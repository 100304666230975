import {
  BackComponent,
  Column,
  InputWithAddon,
  Row,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { DocumentIcon } from '@/icons/DocumentIcon'
import { useContractsOverview, useExportCsv } from '@/services'
import { useLocationTypes } from '@/services/locationTypes'
import { theme } from '@/theme'
import {
  formatMoney,
  fromEventTarget,
  useDebouncedCallback,
  useIsMobile,
} from '@/utils'
import { AdaptiveTooltip, Button, Switch } from '@surein/ui'
import { Archive, Download, Info } from 'lucide-react'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LocationTable from './LocationTable'
import { SkeletonLoader } from './SkeletonLoader'
import {
  ArchiveSwitchRow,
  CompanyAndLocationList,
  Container,
  EmptyContractsSearchContainer,
  LocationTablesWrapper,
  SearchBarContainer,
  SwitchLabel,
  TotalGrossPriceContainer,
} from './styles'

export type Insurance = {
  agreementNumber: string | null
  carrierName: string
  categoryId: string
  name: string
  id: string
  category: string
  contractPaymentNextDate: string | null
  contractStatus: string | null
  endDate: string | null
  grossPrice: number | null
  insuranceId: string
  logoUrl: string
  paymentPeriod: string | null
  policyNumber: string | null
  risksInsured: string | null
  startDate: string | null
  locationName: string
  companyName: string
}

interface ContractsOverviewData {
  companies: {
    name: string
    locations: {
      locationId: string
      name: string
      insurances: Insurance[]
      grossPrice: number
    }[]
    grossPrice: number
    companyId: string
  }[]
}

interface CompanyNameWithOverflowProps {
  name: string
}

const CompanyNameWithOverflow = ({ name }: CompanyNameWithOverflowProps) => {
  const ref = useRef<HTMLSpanElement | null>(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element = ref.current!
    setIsOverflown(element?.scrollWidth > element?.clientWidth)
  }, [])

  return isOverflown ? (
    <AdaptiveTooltip content={name}>
      <Typography bold variant="h4" ref={ref}>
        {name}
      </Typography>
    </AdaptiveTooltip>
  ) : (
    <Typography ref={ref} bold variant="h4">
      {name}
    </Typography>
  )
}

const ArchiveTooltipContent = () => {
  const { t } = useTranslation()
  return (
    <Column gap="8px">
      <Typography bold variant="p1Body">
        {t('archiveInfoTitle')}
      </Typography>
      <Typography color={ThemeColor.b50} variant="p1Body">
        {t('archiveInfoSubtitle')}
      </Typography>
    </Column>
  )
}

const MemoizedLocationTable = memo(LocationTable)

const DownloadIcon = () => (
  <Download color={theme.color[ThemeColor.b50]} size="16" />
)

export const ContractsOverview = () => {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile()
  const [archivedView, setArchivedView] = useState(false)
  const [search, setSearch] = useState('')

  const {
    data: contractsOverviewData,
    isLoading: isContractsOverviewDataLoading,
  } = useContractsOverview(archivedView)

  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const exportCsvMutation = useExportCsv()

  const handleExportCsv = (companyId: string) => {
    exportCsvMutation.mutate({ companyId, archivedView })
  }

  const debouncedSearch = useDebouncedCallback((input: string) => {
    setSearch(input)
  }, 150)

  const { getLocationLabel } = locationTypesData || {}

  return (
    <Container>
      <Column gap="8px">
        <BackComponent noContentGutter noMargin />
        <Typography bold variant="h3">
          {t('contractsOverview')}
        </Typography>
      </Column>
      <Row
        gap="8px"
        alignItems="center"
        justifyContent="space-between"
        wrap="wrap"
      >
        <ArchiveSwitchRow gap="8px" alignItems="center">
          <SwitchLabel htmlFor="archive-switch">
            <Archive color={theme.color.b50} size={24} />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {t('archive')}
            </Typography>
            <Switch
              id="archive-switch"
              checked={archivedView}
              onCheckedChange={setArchivedView}
            />
          </SwitchLabel>
          <AdaptiveTooltip
            // FIXME: AdaptiveTooltip type is marked as string only
            // @ts-expect-error - we should fix AdaptiveTooltip types
            content={<ArchiveTooltipContent />}
          >
            <Info color={theme.color.b50} size={16} />
          </AdaptiveTooltip>
        </ArchiveSwitchRow>

        <SearchBarContainer>
          <InputWithAddon
            addonBefore={() => <SearchIcon />}
            onChange={fromEventTarget(debouncedSearch)}
            placeholder={t('searchByCompanyOrLocation')}
          />
        </SearchBarContainer>
      </Row>
      <CompanyAndLocationList>
        <EmptyContractsSearch
          title={t('noContractsTitle')}
          search={search}
          searchMessage={
            search
              ? t('noMatchInContractsSearch', { search })
              : t('noContractsDescription')
          }
        />
        {/* Fixme: assertion in line 172 Requires TS migration of company service */}
        {isContractsOverviewDataLoading || locationTypesLoading ? (
          <SkeletonLoader />
        ) : (
          ((contractsOverviewData as unknown) as ContractsOverviewData).companies.map(
            ({ name, locations, grossPrice, companyId }) =>
              locations.length &&
              locations.some((location) => location.insurances.length) ? (
                <LocationTablesWrapper key={companyId}>
                  <Row alignItems="center" justifyContent="space-between">
                    <TotalGrossPriceContainer>
                      <CompanyNameWithOverflow name={name} />
                      <Row>
                        <Typography color={ThemeColor.b40} variant="badgeText">
                          {t('total')}
                        </Typography>
                        <Typography color={ThemeColor.b50} variant="badgeText">
                          {`${formatMoney(grossPrice)}/${t('year')}`}
                        </Typography>
                      </Row>
                    </TotalGrossPriceContainer>
                    <Row>
                      <Button
                        icon={DownloadIcon}
                        onClick={() => handleExportCsv(companyId)}
                        size="sm"
                        variant="ghost"
                      >
                        <Typography color={ThemeColor.b50} variant="p2Body">
                          {isMobile ? 'CSV' : t('exportCsv')}
                        </Typography>
                      </Button>
                    </Row>
                  </Row>
                  {locations.map((locationData) =>
                    locationData.insurances.length ? (
                      <MemoizedLocationTable
                        key={locationData.locationId}
                        companyId={companyId}
                        companyName={name}
                        search={search}
                        getLocationLabel={getLocationLabel}
                        {...locationData}
                      />
                    ) : null,
                  )}
                </LocationTablesWrapper>
              ) : null,
          )
        )}
      </CompanyAndLocationList>
    </Container>
  )
}

interface EmptyContractsSearchProps {
  title: string
  search: string
  searchMessage: string
}

const EmptyContractsSearch = ({
  title,
  search,
  searchMessage,
}: EmptyContractsSearchProps) => (
  <EmptyContractsSearchContainer>
    {search ? <SearchIcon size="button" /> : <DocumentIcon size="button" />}
    <Column gap="4px">
      <Typography bold variant="p1Body">
        {title}
      </Typography>
      <Typography color={ThemeColor.b50} variant="p1Body">
        {searchMessage}
      </Typography>
    </Column>
  </EmptyContractsSearchContainer>
)
