import { ImageType, Row, TextBadge, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { Address } from '@/types/address'
import { getFileNameAndExtension, mapWithKey } from '@/utils'
import { LocationCard } from './LocationCard'
import {
  DashboardCompanyCard,
  DashboardCompanyCardHeader,
  StyledHeadquarterLogo,
  StyledHeadquarterLogoContainer,
} from './styles'

interface CompanyDisplayProps {
  isHeadquarters?: boolean
  locations: {
    address: Address
    locationId: string
    name: string
    riskScore: number
    essentialCategoryId: string[]
  }[]
  logoUrl?: string
  name: string
}

export const CompanyDisplay = ({
  name,
  isHeadquarters = true,
  logoUrl,
  locations,
}: CompanyDisplayProps) => {
  const [
    headquartersLogoUrl,
    headquartersLogoExtension,
  ] = getFileNameAndExtension(logoUrl || '')

  return (
    <DashboardCompanyCard isHeadquarters={isHeadquarters}>
      <DashboardCompanyCardHeader isHeadquarters={isHeadquarters}>
        <Typography bold variant="h6">
          {name}
        </Typography>
        {isHeadquarters && logoUrl && (
          <Row alignItems="center" gap="8px">
            <StyledHeadquarterLogoContainer>
              <StyledHeadquarterLogo
                name={headquartersLogoUrl}
                type={headquartersLogoExtension as ImageType}
              />
            </StyledHeadquarterLogoContainer>
            <Typography bold color={ThemeColor.b50} variant="p1Body">
              {name}
            </Typography>
            <TextBadge text="Pro" />
          </Row>
        )}
      </DashboardCompanyCardHeader>
      {mapWithKey(LocationCard, 'locationId', locations)}
    </DashboardCompanyCard>
  )
}
