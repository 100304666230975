import {
  ButtonLink,
  ChecklistWidget,
  Column,
  ContactExpert,
  InsuranceCard,
  InsuranceFinderCard,
  Loader,
  OpenMessageComponent,
  PageMainContent,
  PageWidgetContainer,
  RecommendationCard,
  Row,
  SecondaryButton,
  ShowWhen,
  TextWithLink,
  Typography,
  YearlyPrice,
} from '@/components'
import { Breakpoint, ComponentColors, ThemeColor } from '@/enums'
import { OptimiseCoverCard } from '@/modules/yearlyCheck/components/OptimiseCoverCard'
import { paths } from '@/routes/paths'
import { useLocationHome, useUserProfile } from '@/services'
import { useFeatureFlag } from '@/services/feature-flags'
import {
  daysInFuture,
  formatMoney,
  useGoToPath,
  useIsMobile,
  useLocalStorage,
  useTranslationSection,
} from '@/utils'
import { AdaptiveTooltip, Button } from '@surein/ui'
import { ArrowRight, InfoIcon } from 'lucide-react'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { checkCompletedAssessment } from '../EditLocationPage/helpers'
import { MissingRecommendationCard } from './MissingRecommendationCard'
import {
  isRecommendationNew,
  findEssentialsWithoutRecommendations,
  insuranceFinderSnoozeLocalStorageKey,
  makeCardData,
  makeExpertContent,
  transform,
} from './helpers'
import {
  AddinsuranceContainer,
  ChecklistWidgetContainer,
  InsuranceCardsContainer,
  InsurancesContainer,
  InsurancesHeader,
  MiddleSectionTop,
  UpsellCardsContainer,
} from './styles'
import { theme } from '@/theme'

const useMessages = () => {
  const pageTranslations = useTranslationSection('locationPage')
  const { t } = useTranslation()

  return {
    addInsurance: pageTranslations('addInsurance'),
    myOffers: pageTranslations('myOffers'),
    contracts: pageTranslations('contracts'),
    recommended: pageTranslations('recommended'),
    offerFromPrice: (lowestPrice) =>
      t('offerFromPrice', {
        lowestPrice: formatMoney(lowestPrice),
      }),
    offerInProgress: t('offerInProgress'),
    startRiskAssessment: t('startRiskAssessment'),
    expertOfferReady: (options) => t('expertOfferReady', options),
    expertOfferReadyOne: (options) => t('expertOfferReadyOne', options),
    expertOptimallyInsured: (options) => t('expertOptimallyInsured', options),
    expertAllEssentialInsurances: (options) =>
      t('expertAllEssentialInsurances', options),
    expertMissingEssentialInsurances: (options) =>
      t('expertMissingEssentialInsurances', options),
    expertOfferInProgress: t('expertOfferInProgress'),
    missingDescription: t('missingDescription'),
    makeClaim: t('makeClaim'),
    signPoa: t('profile.menu.signPOA'),
    poaWarn: t('poaSignPage.prePageSubtitle'),
    poaButton: t('locationPoaButton'),
    categories: t('categories', { returnObjects: true }),
  }
}

const InfoTooltipContent = ({ title, description }) => (
  <Column gap="4px">
    <Typography bold variant="footnote">
      {title}
    </Typography>
    <Typography variant="footnote" color={ThemeColor.glass500}>
      {description}
    </Typography>
  </Column>
)

export const LocationPage = () => {
  const { t } = useTranslation()
  const messages = useMessages()
  const { isMobile } = useIsMobile(Breakpoint.sm)
  const { locationId } = useParams()
  const { data, isLoading } = useLocationHome(locationId, {
    select: transform,
  })

  // FIXME: This is a temporary solution to get the user profile refetched
  const {
    data: _userProfileData,
    isLoading: isUserProfileLoading,
  } = useUserProfile()

  const {
    data: riskIndicatorFeatureFlag,
    isLoading: isRiskIndicatorFeatureFlagLoading,
  } = useFeatureFlag('risk-indicator')

  const {
    data: checklistFeatureFlag,
    isLoading: isChecklistFeatureFlagLoading,
  } = useFeatureFlag('risk-checklist')

  const {
    data: yearlyCheckFeatureFlag,
    isLoading: isYearlyCheckFeatureFlagLoading,
  } = useFeatureFlag('yearly-assessment')

  const handleAddInsurance = useGoToPath(paths.addInsuranceContract)

  const goToAssessment = useGoToPath(paths.assessmentWithKey(locationId))

  const [snoozed, setSnoozed] = useLocalStorage(
    insuranceFinderSnoozeLocalStorageKey,
    {},
  )

  const handleSnooze = (categoryId) => {
    setSnoozed({ ...snoozed, [categoryId]: Date.parse(daysInFuture(14)) })
  }

  const upsellCardCategories = useMemo(() => makeCardData(data), [
    JSON.stringify(snoozed),
    data?.insurances,
  ])

  const showYearlyAssessment = data?.yearlyCheckInStatus === 'pending'

  if (
    isLoading ||
    isRiskIndicatorFeatureFlagLoading ||
    isChecklistFeatureFlagLoading ||
    isYearlyCheckFeatureFlagLoading ||
    isUserProfileLoading
  )
    return <Loader />

  const essentialsWithoutRecommendations = findEssentialsWithoutRecommendations(
    data,
  )

  const shouldShowOfferSection = Boolean(data.recommendations.length)

  const shouldShowRecommendationSection =
    Boolean(upsellCardCategories.length) ||
    Boolean(essentialsWithoutRecommendations.length)

  return (
    <>
      <PageMainContent>
        {(yearlyCheckFeatureFlag?.state || showYearlyAssessment) && (
          <OptimiseCoverCard locationId={locationId} />
        )}
        <InsurancesContainer>
          <ShowWhen
            when={isMobile && checklistFeatureFlag?.state && data.riskChecklist}
          >
            <ChecklistWidgetContainer isMobile>
              <ChecklistWidget
                companyId={data.companyId}
                data={data.riskChecklist}
                locationId={locationId}
              />
            </ChecklistWidgetContainer>
          </ShowWhen>
          <ShowWhen when={!riskIndicatorFeatureFlag?.state}>
            <MiddleSectionTop>
              <YearlyPrice insurances={data.insurances} />
            </MiddleSectionTop>
          </ShowWhen>
          {shouldShowOfferSection && (
            <>
              <InsurancesHeader justifyContent="space-between">
                <Row alignItems="center" gap="8px">
                  <Typography variant="h4">{messages.myOffers}</Typography>
                  <AdaptiveTooltip
                    content={
                      <InfoTooltipContent
                        title={t('dashboardInfoTooltips.offers.title')}
                        description={t(
                          'dashboardInfoTooltips.offers.description',
                        )}
                      />
                    }
                  >
                    <InfoIcon size={16} color={theme.color.b50} />
                  </AdaptiveTooltip>
                </Row>
                {isMobile && (
                  <OpenMessageComponent
                    expertButtonComponent={
                      !checkCompletedAssessment(data) && (
                        <SecondaryButton onClick={goToAssessment} size="medium">
                          {messages.startRiskAssessment}
                        </SecondaryButton>
                      )
                    }
                    text={makeExpertContent(data, messages)}
                  />
                )}
              </InsurancesHeader>
              <InsuranceCardsContainer>
                {data.recommendations.map((item) => {
                  const isNew = isRecommendationNew(item.createdAt)

                  return (
                    <RecommendationCard
                      key={item.recommendationId}
                      {...item}
                      important={data.essentialCategoryId?.includes(
                        item.categoryId,
                      )}
                      primaryDisabled={!item.sended || !item.lowestPrice}
                      isNew={item.sended && isNew}
                      primaryText={
                        item.sended && item.lowestPrice
                          ? messages.offerFromPrice(item.lowestPrice)
                          : messages.offerInProgress
                      }
                    />
                  )
                })}
              </InsuranceCardsContainer>
            </>
          )}
          <ShowWhen when={shouldShowRecommendationSection}>
            <InsurancesHeader>
              <Typography variant="h4">{messages.recommended}</Typography>
              <AdaptiveTooltip
                content={
                  <InfoTooltipContent
                    title={t('dashboardInfoTooltips.recommendations.title')}
                    description={t(
                      'dashboardInfoTooltips.recommendations.description',
                    )}
                  />
                }
              >
                <InfoIcon size={16} color={theme.color.b50} />
              </AdaptiveTooltip>
            </InsurancesHeader>
            <UpsellCardsContainer>
              {upsellCardCategories.map((item) => (
                <InsuranceFinderCard
                  key={item}
                  categoryId={item}
                  hide={{ features: true }}
                  onSnooze={() => handleSnooze(item)}
                  primaryCta
                />
              ))}
              {Boolean(essentialsWithoutRecommendations.length) && (
                <>
                  {essentialsWithoutRecommendations.map((categoryId) => {
                    const important = (data.essentialCategoryId || []).includes(
                      categoryId,
                    )

                    return (
                      <MissingRecommendationCard
                        key={categoryId}
                        important={important}
                        inlineButton={!isMobile}
                        categoryId={categoryId}
                      />
                    )
                  })}
                </>
              )}
            </UpsellCardsContainer>
          </ShowWhen>
          <InsurancesHeader>
            <AddinsuranceContainer>
              <Row alignItems="center" gap="8px">
                <Typography variant="h4">{messages.contracts}</Typography>
                <AdaptiveTooltip
                  content={
                    <InfoTooltipContent
                      title={t('dashboardInfoTooltips.contracts.title')}
                      description={t(
                        'dashboardInfoTooltips.contracts.description',
                      )}
                    />
                  }
                >
                  <InfoIcon size={16} color={theme.color.b50} />
                </AdaptiveTooltip>
              </Row>
              <Button
                suffixIcon={ArrowRight}
                onClick={handleAddInsurance}
                variant="outline"
                shape="square"
              >
                {messages.addInsurance}
              </Button>
            </AddinsuranceContainer>
          </InsurancesHeader>
          <InsuranceCardsContainer>
            {data.insurances.map((item) => (
              <InsuranceCard
                key={item.insuranceId || item.categoryId}
                {...item}
                show={{
                  details: item.grossPrice || item.policyNumber,
                }}
              />
            ))}
            <TextWithLink>
              <Trans
                components={
                  <ButtonLink
                    color={ComponentColors.primary}
                    onClick={handleAddInsurance}
                    size="small"
                  />
                }
                i18nKey={messages.missingDescription}
              />
            </TextWithLink>
          </InsuranceCardsContainer>
        </InsurancesContainer>
      </PageMainContent>
      <PageWidgetContainer>
        <Column gap="24px">
          <ShowWhen when={checklistFeatureFlag?.state && data.riskChecklist}>
            <ChecklistWidgetContainer>
              <ChecklistWidget
                companyId={data.companyId}
                data={data.riskChecklist}
                locationId={locationId}
              />
            </ChecklistWidgetContainer>
          </ShowWhen>
          <ContactExpert
            buttonComponent={
              !checkCompletedAssessment(data) && (
                <Button
                  variant="outline"
                  shape="square"
                  onClick={goToAssessment}
                  suffixIcon={ArrowRight}
                >
                  {messages.startRiskAssessment}
                </Button>
              )
            }
            text={makeExpertContent(data, messages)}
          />
        </Column>
      </PageWidgetContainer>
    </>
  )
}
