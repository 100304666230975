/* eslint-disable no-nested-ternary */
import {
  ButtonLink,
  Column,
  ContactExpert,
  ImageFromCdn,
  ImageType,
  InputWithAddon,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  Row,
  TextBadge,
  TextWithLink,
  Typography,
} from '@/components'
import { config } from '@/config'
import { ComponentColors, ThemeColor } from '@/enums'
import { ArrowRight, SearchIcon } from '@/icons'
import { Redirect } from '@/routes/Redirect'
import { paths } from '@/routes/paths'
import { useContractsOverview } from '@/services'
import { Company } from '@/types/company'
import {
  fromEventTarget,
  getFileNameAndExtension,
  includesIgnoringCase,
  makeLocationPathFromCompanies,
  mapWithKey,
  shouldRedirectToDashboard,
  useGoToPath,
  useIsMobile,
} from '@/utils'
import { evolve, isEmpty, map, partition, pluck, prop } from 'ramda'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { CompanyDisplay } from './CompanyDisplay'
import { KPIOverview } from './KPIOverview/Component'
import {
  CompaniesColumn,
  EmptyCompanySearchContainer,
  HeadquartersColumn,
  PageHeader,
  ProHeader,
  ProHeaderLogoWrapper,
  ProHeaderTextContainer,
  ProSection,
} from './styles'

interface KPIs {
  companies: {
    companyId: string
    name: string
    grossPrice: number
  }[]
  other: {
    grossPrice: number
  }
  totalGrossPrice: number
}

const filterCompanies = (companies: Company[] = [], search: string) =>
  companies
    .filter(
      (company) =>
        includesIgnoringCase(search, company.name) ||
        company.locations.find((location) =>
          includesIgnoringCase(search, location.name),
        ),
    )
    .map((company) => {
      const filteredCompany = company.locations.filter((location) =>
        includesIgnoringCase(search, location.name),
      )
      const isPerfectMatch = search.toLowerCase() === company.name.toLowerCase()
      return {
        ...company,
        locations: isPerfectMatch ? company.locations : filteredCompany,
      }
    })

export const Dashboard = () => {
  const { user, companies: data = [] } = useOutletContext<{
    user: { name: string }
    companies: Company[]
  }>()
  const [search, setSearch] = useState('')
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const {
    data: contractsOverviewData,
    isLoading: isContractsOverviewDataLoading,
  } = useContractsOverview()

  const goToCompaniesAndLocations = useGoToPath(paths.companies)
  const goToContractsOverview = useGoToPath(paths.contractsOverview)

  if (isEmpty(data)) return <Redirect to={paths.authRedirect} />

  if (!shouldRedirectToDashboard(data))
    return (
      <Redirect
        to={makeLocationPathFromCompanies(paths)(
          map(evolve({ locations: pluck('locationId') }), data),
        )}
      />
    )
  const [headquarters, companies] = partition(prop('isHeadquarters'), data)

  const headquartersWithLogo = headquarters.find((hq) => !!hq.logoUrl)

  const [
    headquartersLogoUrl,
    headquartersLogoExtension,
  ] = getFileNameAndExtension(headquartersWithLogo?.logoUrl || '')

  if (isContractsOverviewDataLoading) return <Loader />

  const filteredCompanies = filterCompanies(companies, search)
  const filteredHeadquarters = filterCompanies(headquarters, search)

  return (
    <>
      <PageMainContent>
        <ProSection>
          {headquarters.length && headquartersLogoUrl ? (
            <>
              <ProHeader
                logoUrl={`${config.cdn}/${headquartersWithLogo?.bannerUrl}`}
              >
                <ProHeaderTextContainer
                  hasLogo={headquarters.length && headquartersLogoUrl}
                >
                  <Row alignItems="center" gap="6px">
                    <Typography
                      color={ThemeColor.b0}
                      responsive
                      variant={isMobile ? 'inputLabel' : 'button'}
                    >
                      {t('companyOverview')}
                    </Typography>
                    <TextBadge bgOpacity="0.5" text="Pro" />
                  </Row>
                  <Typography
                    color={ThemeColor.b0}
                    responsive
                    variant={isMobile ? 'h4' : 'h3'}
                  >
                    {t('welcome_text_dashboard', { name: user.name })}
                  </Typography>
                </ProHeaderTextContainer>
                <Column>
                  <ProHeaderLogoWrapper>
                    <ImageFromCdn
                      name={headquartersLogoUrl}
                      type={headquartersLogoExtension as ImageType}
                    />
                  </ProHeaderLogoWrapper>
                </Column>
              </ProHeader>
              {/* TODO: Come back to this after archived contracts overview PR is merged
                since this interface is altered in the PR */}
              <KPIOverview
                data={
                  ((contractsOverviewData as unknown) as { kpis: KPIs }).kpis
                }
                grossPrice={
                  ((contractsOverviewData as unknown) as { kpis: KPIs }).kpis
                    .totalGrossPrice
                }
                onDetailsClick={goToContractsOverview}
              />
            </>
          ) : headquarters.length ? (
            <ProHeaderTextContainer hasLogo={!!headquartersLogoUrl}>
              <Row alignItems="center" gap="6px">
                <Typography
                  bold={!headquartersLogoUrl}
                  color={headquartersLogoUrl ? ThemeColor.b0 : ThemeColor.b100}
                  responsive
                  variant={isMobile ? 'inputLabel' : 'button'}
                >
                  {t('companyOverview')}
                </Typography>
                <TextBadge
                  bgOpacity={headquartersLogoUrl ? 0.5 : 1}
                  text="Pro"
                />
              </Row>
              <Typography
                bold={!headquartersLogoUrl}
                color={headquartersLogoUrl ? ThemeColor.b0 : ThemeColor.b100}
                responsive
                variant={isMobile ? 'h4' : 'h3'}
              >
                {t('welcome_text_dashboard', { name: user.name })}
              </Typography>
            </ProHeaderTextContainer>
          ) : (
            <PageHeader>
              <Typography variant="h4">
                {t('welcome_text_dashboard', { name: user.name })}
              </Typography>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {t('subtext_dashboard')}
              </Typography>
            </PageHeader>
          )}
        </ProSection>
        <CompaniesColumn hasHeadquarters={!!headquarters.length}>
          <Typography bold variant="h4">
            {t('companiesAndLocations')}
          </Typography>
          <InputWithAddon
            addonBefore={() => <SearchIcon />}
            onChange={fromEventTarget(setSearch)}
            placeholder={t('searchByCompanyOrLocation')}
            value={search}
          />
          {!!headquarters.length && (
            <HeadquartersColumn>
              {mapWithKey(CompanyDisplay, 'companyId', filteredHeadquarters)}
            </HeadquartersColumn>
          )}
          {search &&
          filteredCompanies.length === 0 &&
          filteredHeadquarters.length === 0 ? (
            <EmptyCompanySearch
              searchMessage={t('noMatchInSearch', { search })}
            />
          ) : (
            mapWithKey(CompanyDisplay, 'companyId', filteredCompanies)
          )}
          <TextWithLink>
            <Trans
              components={{
                ButtonLink: (
                  <ButtonLink
                    color={ComponentColors.primary}
                    onClick={goToCompaniesAndLocations}
                  >
                    {t('add_company_or_location_text')}
                  </ButtonLink>
                ),
              }}
              i18nKey={t('add_company_or_location_text')}
            />
          </TextWithLink>
        </CompaniesColumn>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('dashboard_advisor_text')} />
      </PageWidgetContainer>
    </>
  )
}

interface EmptyCompanySearchProps {
  searchMessage: string
}

const EmptyCompanySearch = ({ searchMessage }: EmptyCompanySearchProps) => {
  const { t } = useTranslation()
  const goToCompaniesAndLocations = useGoToPath(paths.companies)

  return (
    <EmptyCompanySearchContainer>
      <SearchIcon size="button" />
      <Column gap="4px">
        <Typography bold variant="p1Body">
          {t('noResultsFound')}
        </Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {searchMessage}
        </Typography>
      </Column>
      <ButtonLink onClick={goToCompaniesAndLocations}>
        <Row alignItems="center">
          <Typography bold color={ThemeColor.electric500} variant="p1Body">
            {t('createCompany')}
          </Typography>
          <ArrowRight size="message" />
        </Row>
      </ButtonLink>
    </EmptyCompanySearchContainer>
  )
}
