import { Breakpoint } from '@/enums'
import { theme } from '@/theme/theme'
import PropTypes from 'prop-types'
import { values } from 'ramda'
import { useCallback, useLayoutEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { debounce } from '../function'

export const useIsMobile = (breakpoint) => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const handleSize = useCallback(
    debounce(() => {
      const width = window.innerWidth
      const height = window.innerHeight

      setWindowSize((prev) => {
        if (prev.width === width && prev.height === height) return prev
        return { width, height }
      })
    }, 150),
    [],
  )

  useLayoutEffect(() => {
    handleSize()
    window.addEventListener('resize', handleSize)
    return () => window.removeEventListener('resize', handleSize)
  }, [handleSize])

  return {
    mobileDevice: isMobile,
    isMobile: windowSize.width < (theme.breakpoints.values[breakpoint] || 769),
    width: windowSize.width,
    height: windowSize.height,
    isLandscape: window.matchMedia('(orientation: landscape)').matches,
    windowSize,
  }
}

useIsMobile.propTypes = {
  breakpoint: PropTypes.oneOf(values(Breakpoint)),
}
