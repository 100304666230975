import { Column, Flex, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { theme } from '@/theme'
import { themeBreakpointDown, useGoToPath, useIsMobile } from '@/utils'
import { Button } from '@surein/ui'
import { Check } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled(Column)`
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 78px;
  gap: 32px;

  ${themeBreakpointDown('sm')} {
    margin-bottom: 0;
    > &:last-child {
    align-self: stretch;
  }
`

const TopArea = styled(Column)`
  gap: 24px;
  align-items: center;
  justify-content: center;
  ${themeBreakpointDown('sm')} {
    flex: 2;
  }
`

const BottomArea = styled(Flex)`
  gap: 8px;
  ${themeBreakpointDown('sm')} {
    align-self: stretch;
  }
`

const TextSection = styled(Column)`
  gap: 12px;
  > * {
    text-align: center;
  }
`

const CheckmarkBackground = styled(Column)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.success1};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    flex-shrink: 0;
  }
`

export const SuccessStep = () => {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile()
  const restartProcess = useGoToPath(paths.addInsuranceContract)
  const goToDashboard = useGoToPath(paths.dashboard)

  return (
    <Container>
      <TopArea>
        <CheckmarkBackground>
          <Check strokeWidth={3} color={theme.color.b0} />
        </CheckmarkBackground>
        <TextSection>
          <Typography variant="itemTitle">
            {t('addInsuranceContractsSteps.success.title')}
          </Typography>
          <Typography variant="description" color={ThemeColor.glass500}>
            {t('addInsuranceContractsSteps.success.subtitle')}
          </Typography>
        </TextSection>
      </TopArea>
      <BottomArea flexDirection={isMobile ? 'column' : 'row'} gap="8px">
        <Button onClick={restartProcess}>
          {t('addInsuranceContractsSteps.success.addNew')}
        </Button>
        <Button variant="secondary" onClick={goToDashboard}>
          {t('addInsuranceContractsSteps.success.backButton')}
        </Button>
      </BottomArea>
    </Container>
  )
}
