import { GetSelfAssessmentApiResponse } from '@/api'
import {
  BackComponent,
  Column,
  Image,
  Loader,
  PageMainContent,
  Row,
} from '@/components'
import { useSegment } from '@/modules'
import { FormProvider, useContactModal } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useAssessment,
  useRequestRecommendation,
  useSaveAssessment,
} from '@/services'
import { themeBreakpointDown, useGoToPath, useIsMobile } from '@/utils'
import { Button } from '@surein/ui'
import { ArrowRightIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { NoOfferPage } from '../SelfAssessmentPage/public/NoOfferPage'
import {
  autoRecommendationCategories,
  categoryToSectionMap,
  makeInitialValues,
  sectionComponents,
} from './helpers'
import { makeValidationSchema } from './validationSchema'

const MainContent = styled(PageMainContent)`
  grid-column: col-start 4 / span 7;
  padding-bottom: 0;

  @media (max-width: 1160px) {
    grid-column: col-start 4 / span 7;
  }

  @media (max-width: 980px) {
    grid-column: col-start 5 / span 8;
  }

  @media (max-width: 655px) {
    grid-column: col-start / span 2 !important;
  }

  ${themeBreakpointDown('sm')} {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Container = styled(Column)`
  height: 88vh;
  width: 100%;

  ${themeBreakpointDown('sm')} {
    height: 87.5vh;
  }
`

const ScrollableContent = styled(Column)`
  flex: 1;
  overflow-y: auto;
  gap: 24px;
  padding: 0 8px 24px 8px;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`

const FormWrapper = styled(Column)`
  gap: 24px;
  height: 100%;

  > form {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }
`

const HeaderContainer = styled(Column)`
  gap: 8px;
  padding: 0 8px;
`

const FooterContainer = styled(Row)`
  align-items: center;
  padding: 16px 8px;
  border-top: 1px solid ${(props) => props.theme.color.glass200};
  justify-content: space-between;

  > button:first-child {
    padding: 8px 16px 8px 8px;
  }

  ${themeBreakpointDown('sm')} {
    justify-content: end;

    > button:first-child {
      padding: 16px;
    }
  }
`

const ContactButtonImage = styled(Image)`
  height: 32px;
  width: 32px;
  border-radius: 100%;
`

interface FormValues {
  businessType: string
  locationType: string
  locationSubtypes: string[]
  locationTypeSpecification?: string
  lastYearFinances: {
    approxTurnover: number
    approxPersonelExpenses: number
    approxCostOfGoods: number
    approximateCostOfElectroEquipment: number
  }
  locationRent: {
    totalEquipmentsCost: number
  }
  employeesCount: {
    ownersCount: number
    fullTimeEmployeesCount: number
    partTimeEmployeesCount: number
    miniJobbersCount: number
  }
  locationDetails: {
    name: string
    address: {
      street: string
      streetNumber: string
      postalCode: string
      city: string
      country: string
    }
  }
  companyFoundedDate: {
    foundedDate: string
  }
}

const makeSubmitValues = ({
  lastYearFinances,
  locationRent,
  employeesCount,
  locationDetails,
  companyFoundedDate,
  ...values
}: FormValues): Partial<GetSelfAssessmentApiResponse['values']> => {
  if (locationDetails) {
    locationDetails.address.country = 'Germany'
  }

  return {
    businessType: {
      locationType: values.locationType,
      locationSubtypes: values.locationSubtypes,
      businessType: values.businessType,
      locationTypeSpecification: values.locationTypeSpecification,
    },
    lastYearFinances,
    locationRent,
    employeesCount,
    locationDetails,
    companyFoundedDate,
  }
}

interface RequestOfferFormProps {
  categoryId: string
}

const RequestOfferForm = ({ categoryId }: RequestOfferFormProps) => {
  const sections = categoryToSectionMap[categoryId]

  return (
    <Column gap="24px">
      {sections.map((sectionKey: string) => {
        const SectionComponent = sectionComponents[sectionKey]
        return <SectionComponent key={sectionKey} categoryId={categoryId} />
      })}
    </Column>
  )
}

const ContactButtonIcon = () => (
  <ContactButtonImage name="chatblock" type="png" />
)

interface FormFooterProps {
  isMobile: boolean
  openContact: () => void
  updateAssessmentLoading: boolean
  requestOfferLoading: boolean
}

const FormFooter = ({
  isMobile,
  openContact,
  updateAssessmentLoading,
  requestOfferLoading,
}: FormFooterProps) => {
  const { t } = useTranslation()

  return (
    <FooterContainer>
      {!isMobile && (
        <Button
          icon={ContactButtonIcon}
          onClick={openContact}
          variant="outline"
          type="button"
        >
          {t('common.contactUs')}
        </Button>
      )}
      <Button
        suffixIcon={ArrowRightIcon}
        variant="primary"
        type="submit"
        shape="square"
        loading={updateAssessmentLoading || requestOfferLoading}
      >
        {t('requestOfferPage.submit')}
      </Button>
    </FooterContainer>
  )
}

export const RequestOffer = () => {
  const { t } = useTranslation()
  const { locationId, categoryId } = useParams()
  const { openContact } = useContactModal()
  const goToRecommendation = useGoToPath(paths.viewRecommendationWithKey)
  const { isMobile } = useIsMobile()
  const [errorState, setErrorState] = useState(false)
  const analytics = useSegment()

  const { data, isLoading } = useAssessment(locationId)
  const updateAssessmentMutation = useSaveAssessment(locationId!, false, false)
  const requestOfferMutation = useRequestRecommendation(locationId!)

  const handleSubmit = async (values: FormValues) => {
    try {
      await updateAssessmentMutation.mutateAsync(makeSubmitValues(values))
      await requestOfferMutation
        .mutateAsync({ categoryId: categoryId! })
        .then(async (requestOfferData) => {
          if (requestOfferData?.recommendationId) {
            await analytics.track('app_request_offer_success', {
              categoryId,
            })
            goToRecommendation(requestOfferData.recommendationId)
          } else {
            await analytics.track('app_request_offer_failed', {
              categoryId,
            })
            setErrorState(true)
          }
        })
    } catch (_e) {
      setErrorState(true)
    }
  }

  useEffect(() => {
    if (categoryId) {
      analytics.track('app_self_assessment_start', {
        categoryId,
      })
    }
  }, [])

  if (categoryId && !autoRecommendationCategories.includes(categoryId!)) {
    return
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorState) {
    return <NoOfferPage />
  }

  return (
    <MainContent>
      <Container>
        <FormWrapper>
          <FormProvider
            initialValues={makeInitialValues(data?.values, categoryId!)}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={makeValidationSchema(
              t('validations', { returnObjects: true }),
              categoryId!,
            )}
          >
            <ScrollableContent>
              <HeaderContainer />
              <Column gap="24px">
                <BackComponent noContentGutter />
                <RequestOfferForm categoryId={categoryId!} />
              </Column>
            </ScrollableContent>
            <FormFooter
              isMobile={isMobile}
              openContact={openContact}
              updateAssessmentLoading={updateAssessmentMutation.isLoading}
              requestOfferLoading={requestOfferMutation.isLoading}
            />
          </FormProvider>
        </FormWrapper>
      </Container>
    </MainContent>
  )
}
